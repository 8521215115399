import i18next from "i18next";
import {
  capitalizeFirstCharacterInString,
  formatAmount,
  getCategoryForContractItems,
  getColumnDetail,
  getCustomerAddress,
  getCustomerAddressForExcel,
  getOnlyCustomerName,
  getTypeFromValue,
} from "./utils";
import { METAL_OPTIONS, TAG_BUREAUCRACY_OPTIONS } from "./constants/selectOptions";

// saleroom title
const getSaleRoomTitle = (item) => {
  let des = [];
  if (item?.contract_item?.author?.id) {
    des.push(item?.contract_item?.author?.description);
  }
  if (item?.contract_item?.title_en) {
    des.push(item?.contract_item?.title_en);
  }
  return des.length > 0 ? des.join(", ") : "-";
};

// saleroom descreption
const getDescreptionForSaleRoomExportData = (item) => {
  let des = [];
  if (item?.contract_item?.author?.id) des.push(`${item?.contract_item?.author?.description}`);
  if (item?.contract_item?.title_en) des.push(`${item?.contract_item?.title_en}`);
  if (item?.contract_item?.description_en) des.push(`${item?.contract_item?.description_en}`);
  if (item?.contract_item?.dimensions_en) des.push(`${item?.contract_item?.dimensions_en}`);
  if (item?.contract_item?.weight_en) des.push(`${item?.contract_item?.weight_en}`);
  if (item?.contract_item?.notes_en) des.push(`${item?.contract_item?.notes_en}`);
  if (item?.contract_item?.provenance_en) des.push(`${item?.contract_item?.provenance_en}`);
  if (item?.contract_item?.exhibitions_en) des.push(`${item?.contract_item?.exhibitions_en}`);
  if (item?.contract_item?.literature_en) des.push(`${item?.contract_item?.literature_en}`);

  return des.length > 0 ? des.join("\n") : "-";
};

// generate saleroom data for exporting
const generateSaleRoomExportData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      "Lot Number": item?.lot_no ? item?.lot_no + item?.lot_alpha : "-",
      Title: getSaleRoomTitle(item),
      Description: getDescreptionForSaleRoomExportData(item),
      "Start Price": item?.contract_item?.min_estimate ? `${item?.contract_item?.min_estimate}` : "-",
      "Low Estimate": item?.contract_item?.min_estimate ? `${item?.contract_item?.min_estimate}` : "-",
      "High Estimate": item?.contract_item?.max_estimate ? `${item?.contract_item?.max_estimate}` : "-",
    };
    return data;
  });
};

// invaluable descreption
const getDescreptionForInvalueExportData = (item) => {
  let des = [];

  if (item?.contract_item?.author?.id) des.push(`${item?.contract_item?.author?.description}`);

  if (item?.contract_item?.title_en) des.push(`${item?.contract_item?.title_en}`);

  if (item?.contract_item?.description_en) des.push(`${item?.contract_item?.description_en}`);

  if (item?.contract_item?.dimensions_en) des.push(`${item?.contract_item?.dimensions_en}`);

  if (item?.contract_item?.weight_en) des.push(`${item?.contract_item?.weight_en}`);

  if (des.length === 0) return "-";

  // Ensure the first two elements are joined with a space and the rest with "\n"
  return des.length > 2 
    ? `${des[0]} ${des[1]}\n${des.slice(2).join("\n")}` 
    : des.join(" ");
};

// invaluable title
const getInvaluableTitle = (item) => {
  let des = [];
  if (item?.contract_item?.author?.id) {
    des.push(item?.contract_item?.author?.description);
  }
  if (item?.contract_item?.title_en) {
    des.push(item?.contract_item?.title_en);
  }
  return des.length > 0 ? des.join(", ") : "-";
};

// invaluable dimentions
const getDimensionsForInvaluableExportdata = (item) => {
  let des = [];

  if (item?.contract_item?.dimensions_en) des.push(`${item?.contract_item?.dimensions_en}`);

  if (item?.contract_item?.weight_en) des.push(`${item?.contract_item?.weight_en}`);

  return des?.length > 0 ? des.join("\n") : "-";
};

// generate Invaluable data for exporting
const generateInvaluableExportData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      "Lot Number": item?.lot_no ? item?.lot_no + item?.lot_alpha : "-",
      "Lot Ext": "",
      "Lot Title": getInvaluableTitle(item),
      "Lot Description": getDescreptionForInvalueExportData(item),
      "Lo Est": item?.contract_item?.min_estimate ? `${item?.contract_item?.min_estimate}` : "-",
      "Hi Est": item?.contract_item?.max_estimate ? `${item?.contract_item?.max_estimate}` : "-",
      "Starting Bid": item?.contract_item?.min_estimate ? `${item?.contract_item?.min_estimate}` : "-",
      Dimensions: getDimensionsForInvaluableExportdata(item),
      "Ship Length": "",
      "Ship Height": "",
      "Ship Width": "",
      "Ship Weight": "",
      "Inv Number": "",
      Notes: item?.contract_item?.notes_en ? item?.contract_item?.notes_en : "-",
      Provenance: item?.contract_item?.provenance_en ? item?.contract_item?.provenance_en : "-",
      "Artist Name": item?.contract_item?.author?.id ? item?.contract_item?.author?.description : "-",
      Exhibited: item?.contract_item?.exhibitions_en ? item?.contract_item?.exhibitions_en : "-",
      Literature: item?.contract_item?.literature_en ? item?.contract_item?.literature_en : "-",
      Medium: "",
      Circa: "",
    };

    return data;
  });
};

// generate description
const getDescriptionForArtPriceExportData = (item) => {
  let des = [];
  if (item?.contract_item?.description_en) des.push(`${item?.contract_item?.description_en}`);
  if (item?.contract_item?.period?.id) des.push(`${item?.contract_item?.period?.title_en}`);

  return des?.length > 0 ? des.join("\n") : "";
};

// special notes
const getSpecialNote = (item) => {
  let des = [];
  if (item?.contract_item?.notes_en) des.push(item?.contract_item?.notes_en);

  if (item?.contract_item?.provenance_en) des.push(item?.contract_item?.provenance_en);

  if (item?.contract_item?.exhibitions_en) des.push(item?.contract_item?.exhibitions_en);

  if (item?.contract_item?.literature_en) des.push(item?.contract_item?.literature_en);

  return des?.length > 0 ? des.join("\n") : "-";
};

// generate art price data for exporting
const generateArtPriceExportData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      "Lot number": item?.lot_no ? item?.lot_no + item?.lot_alpha : "",
      Artist: item?.contract_item?.author?.id ? item?.contract_item?.author?.description : "",
      Title: item?.contract_item?.title_en ? item?.contract_item?.title_en : "",
      Description: getDescriptionForArtPriceExportData(item),
      "Special Notes": getSpecialNote(item),

      Dimensions: item?.contract_item?.dimensions_en
        ? `${item?.contract_item?.dimensions_en}`
        : "",
      Weight: item?.contract_item?.weight_en ? `${item?.contract_item?.weight_en}` : "",
      "Low Estimate": item?.contract_item?.min_estimate ? `${item?.contract_item?.min_estimate}` : "",
      "High Estimate": item?.contract_item?.max_estimate ? `${item?.contract_item?.max_estimate}` : "",
      "Hammer Price": item?.contract_item?.hammer_price ? `${item?.contract_item?.hammer_price}` : "",
    };

    return data;
  });
};

// get catalog title pt
const getCatalogTitle = (item, type) => {
  
  if (type === "pt") {
    if (item?.contract_item?.author?.id) {
      return item.contract_item.author.description.toString();
    } else if (item?.contract_item?.title_pt) {
      return item.contract_item.title_pt;
    }
    return "";
  
  } else if (type === "en") {
    if (item?.contract_item?.author?.id) {
      return item.contract_item.author.description.toString();
    } else if (item?.contract_item?.title_en) {
      return item.contract_item.title_en;
    }
    return "";
  }

};

// generate description for catalogue
const getCatalogueDescription = (item, type) => {
  let des = [];

  if (type === "pt") {
    if (item?.contract_item?.author?.id && item?.contract_item?.title_pt) {
      des.push(`${item.contract_item.title_pt}`);
    }
    if (item?.contract_item?.description_pt) {
      des.push(`${item.contract_item.description_pt}`);
    }
  } else if (type === "en") {
    if (item?.contract_item?.author?.id && item?.contract_item?.title_en) {
      des.push(`${item.contract_item.title_en}`);
    }
    if (item?.contract_item?.description_en) {
      des.push(`${item.contract_item.description_en}`);
    }
  }

  return des.length > 0 ? des.join("\n") : " ";
};

const getCatalogueNote = (item, type) => {
  let des = [];
  if (type === "pt") {
    if (item?.contract_item?.notes_pt) des.push(`Nota: \n${item?.contract_item?.notes_pt}`);

    if (item?.contract_item?.provenance_pt)
      des.push(`\nProveniência: \n${item?.contract_item?.provenance_pt}`);

    if (item?.contract_item?.exhibitions_pt)
      des.push(`\nExposições/Exhibitions: \n${item?.contract_item?.exhibitions_pt}`);

    if (item?.contract_item?.literature_pt)
      des.push(`\nBibliografia/Literature: \n${item?.contract_item?.literature_pt}`);
  } else if (type === "en") {
    
    if (item?.contract_item?.notes_en) des.push(`Note: \n${item?.contract_item?.notes_en}`);

    if (item?.contract_item?.provenance_en)
      des.push(`\nProvenance: \n${item?.contract_item?.provenance_en}`);

    if (item?.contract_item?.exhibitions_pt)
      des.push(`\nExhibitions: \n${item?.contract_item?.exhibitions_en}`);

    if (item?.contract_item?.literature_pt)
      des.push(`\nLiterature: \n${item?.contract_item?.literature_en}`);
  }
  return des?.length > 0 ? des.join("\n") : " ";
};

// generate catalogue data for exporting
const generateCatalogueExportData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      [`${i18next.t("common.lot")} ${i18next.t("common.no_")}`]: item?.lot_no ? item?.lot_no + item?.lot_alpha : " ",
      [`${i18next.t("common.contractNo")}`]: item?.contract_item?.contract_item_no
        ? `${item?.contract_item?.contract_item_no}`
        : " ",
      [`${i18next.t("common.title")}`]: getCatalogTitle(item, "pt"),
      [`${i18next.t("common.description")}`]: getCatalogueDescription(item, "pt"),
      [`${i18next.t("common.dimensions")}`]: item?.contract_item?.dimensions_catalog
        ? `${item?.contract_item?.dimensions_catalog}`
        : " ",
      [`${i18next.t("common.weight")}`]: item?.contract_item?.weight_catalog
        ? `${item?.contract_item?.weight_catalog}`
        : " ",
      [`${i18next.t("common.notes")}`]: getCatalogueNote(item, "pt"),
      [`${i18next.t("common.title")} ${i18next.t("common.en")}`]: getCatalogTitle(item, "en"),
      [`${i18next.t("common.description")} ${i18next.t("common.en")}`]: getCatalogueDescription(item, "en"),
      [`${i18next.t("common.notes")} ${i18next.t("common.en")}`]: getCatalogueNote(item, "en"),
      [`${i18next.t("common.valor")}`]: `${
        item?.contract_item?.min_estimate ? `${formatAmount(item?.contract_item?.min_estimate)}` : `0`
      } - ${item?.contract_item?.max_estimate ? `${formatAmount(item?.contract_item?.max_estimate)}` : `0`} €`,
    };

    return data;
  });
};

const getAddress = (addressArr, addressType) => {
  const add = addressArr?.find((address) => address?.address_type === addressType);
  const address = [];
  if (add?.address) address.push(add?.address);
  if (add?.post_code && add?.city) {
    address.push(`${add?.post_code} ${add?.city}`);
  } else {
    if (add?.post_code) address.push(add?.post_code);
    if (add?.city) address?.push(add?.city);
  }
  if (add?.country?.name) address.push(add?.country?.name);
  return address.length > 0 ? address.join("\n") : "-";
};

// generate generalPJMap data generator
const generateGeneralPjMapExportData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      [`${i18next.t("common.client")} ${i18next.t("common.name")}`]: item?.contract_item?.contract?.customer?.id
        ? `${getOnlyCustomerName(item?.contract_item?.contract?.customer)}`
        : "",
      [`${i18next.t("common.idOrpassport")}`]: item?.contract_item?.contract?.customer?.passport_no
        ? `${item?.contract_item?.contract?.customer?.passport_no}`
        : "",
      [`${i18next.t("common.vat")}`]: item?.contract_item?.contract?.customer?.client_vat
        ? item?.contract_item?.contract?.customer?.client_vat
        : "",
      [`${i18next.t("common.address")}`]: getAddress(
        item?.contract_item?.contract?.customer?.addresses,
        item?.contract_item?.contract?.customer?.customer_type === "company" ? "company" : "client",
      ),
      [`${i18next.t("common.artist")}`]: item?.contract_item?.author?.id
        ? item?.contract_item?.author?.description
        : "",
      [`${i18next.t("common.title")}`]: item?.contract_item?.title_pt ? `${item?.contract_item?.title_pt}` : "",
      [`${i18next.t("common.description")}`]: item?.contract_item?.description_pt
        ? `${item?.contract_item?.description_pt}`
        : "",
    };
    return data;
  });
};

// get description for PJ jwewllery
const getDescriptionForPJJewellery = (item) => {
  const des = [];
  if (item?.contract_item?.title_pt) des.push(item?.contract_item?.title_pt);
  if (item?.contract_item?.description_pt) des.push(item?.contract_item?.description_pt);
  return des?.length > 0 ? des.join("\n") : "";
};

// generate jewelryPjMap data
const generateJewelryPjMapExportData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      [`${i18next.t("common.client")} ${i18next.t("common.name")}`]: item?.contract_item?.contract?.customer?.id
        ? getOnlyCustomerName(item?.contract_item?.contract?.customer)
        : "",
      [`${i18next.t("common.idOrpassport")}`]: item?.contract_item?.contract?.customer?.passport_no
        ? `${item?.contract_item?.contract?.customer?.passport_no}`
        : "",
      [`${i18next.t("common.address")}`]: getAddress(
        item?.contract_item?.contract?.customer?.addresses,
        item?.contract_item?.contract?.customer?.customer_type === "company" ? "company" : "client",
      ),
      [`${i18next.t("common.amount")}`]: "",
      [`${i18next.t("common.classification")}`]: "",
      [`${i18next.t("common.weight")}`]: item?.contract_item?.weight_pt
        ? `${item?.contract_item?.weight_pt}`
        : "",
      [`${i18next.t("common.metal")}`]:
      getTypeFromValue(METAL_OPTIONS, item?.contract_item?.metal)?.label || item?.contract_item?.metal || "-",
      [`${i18next.t("common.description")}`]: getDescriptionForPJJewellery(item),
    };
    return data;
  });
};

// get description for asae
const getDescriptionForASAE = (item) => {
  const des = [];
  if (item?.contract_item?.title_pt) des.push(item?.contract_item?.title_pt);
  if (item?.contract_item?.description_pt) des.push(item?.contract_item?.description_pt);
  return des?.length > 0 ? des.join("\n") : "";
};

// generate ASAE export
const generateASAEExportData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      [`${i18next.t("common.client")} ${i18next.t("common.name")}`]: item?.contract_item?.contract?.customer?.id
        ? getOnlyCustomerName(item?.contract_item?.contract?.customer)
        : "",
      [`${i18next.t("common.idOrpassport")}`]: item?.contract_item?.contract?.customer?.passport_no
        ? `${item?.contract_item?.contract?.customer?.passport_no}`
        : "",
      [`${i18next.t("common.address")}`]: getAddress(
        item?.contract_item?.contract?.customer?.addresses,
        item?.contract_item?.contract?.customer?.customer_type === "company" ? "company" : "client",
      ),
      [`${i18next.t("common.weight")}`]: item?.contract_item?.weight_pt
        ? `${item?.contract_item?.weight_pt}`
        : "",
      [`${i18next.t("common.metal")}`]: item?.contract_item?.metal
        ? getTypeFromValue(METAL_OPTIONS, item?.contract_item?.metal)?.label || "-"
        : "",
      [`${i18next.t("common.description")}`]: getDescriptionForASAE(item),
    };
    return data;
  });
};

const getWordpressNotesData = (item, isPT) => {
  const notes = [];
  if (isPT) {
    if (item?.contract_item?.notes_pt !== "" && item?.contract_item?.notes_pt !== "-") {
      notes.push(item?.contract_item?.notes_pt);
      notes.push("\n\n");
    }
    if (item?.contract_item?.provenance_pt !== "" && item?.contract_item?.provenance_pt !== "-") {
      notes.push("Proveniência:");
      notes.push("\n");
      notes.push(item?.contract_item?.provenance_pt);
      notes.push("\n\n");
    }
    if (item?.contract_item?.exhibitions_pt !== "" && item?.contract_item?.exhibitions_pt !== "-") {
      notes.push("Exposições:");
      notes.push("\n");
      notes.push(item?.contract_item?.exhibitions_pt);
      notes.push("\n\n");
    }
      
    if (item?.contract_item?.literature_pt !== "" && item?.contract_item?.literature_pt !== "-") {
      notes.push("Bibliografia:");
      notes.push("\n");
      notes.push(item?.contract_item?.literature_pt);
      notes.push("\n\n");
    }
  } else {
    if (item?.contract_item?.notes_en !== "" && item?.contract_item?.notes_en !== "-") {
      notes.push(item?.contract_item?.notes_en);
      notes.push("\n\n");
    }
    if (item?.contract_item?.provenance_en !== "" && item?.contract_item?.provenance_en !== "-") {
      notes.push("Provenance:");
      notes.push("\n");
      notes.push(item?.contract_item?.provenance_en);
      notes.push("\n\n");
    }
    if (item?.contract_item?.exhibitions_en !== "" && item?.contract_item?.exhibitions_en !== "-") {
      notes.push("Exhibitions:");
      notes.push("\n");
      notes.push(item?.contract_item?.exhibitions_en);
      notes.push("\n\n");
    }
    if (item?.contract_item?.literature_en !== "" && item?.contract_item?.literature_en !== "-") {
      notes.push("Literature:");
      notes.push("\n");
      notes.push(item?.contract_item?.literature_en);
      notes.push("\n\n"); 
    }
  }
  return notes?.length > 0 ? notes.join("") : "";
};

// generate wordpress export
const generateWordpressExportData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      "Nº Lote": item?.lot_no ? item?.lot_no + item?.lot_alpha : "",
      "No. Contrato": item?.contract_item?.contract_item_no || "",
      "Descrição Sumaria": item?.contract_item?.title_pt || "",
      Descrição: item?.contract_item?.description_pt || "",
      "Notas especiais": getWordpressNotesData(item, true),
      Dimensão: item?.contract_item?.dimensions_pt || "",
      Peso: item?.contract_item?.weight_pt || "",
      "Short description": item?.contract_item?.title_en || "",
      Description: item?.contract_item?.description_en || "",
      "Special notes": getWordpressNotesData(item),
      "Valor minimo": item?.contract_item?.min_estimate || "",
      "Valor maximo": item?.contract_item?.max_estimate || "",
      Autor: item?.contract_item?.author?.id ? item?.contract_item?.author?.description : "",
      "Valor Venda": item?.sale_value || "",
      Categoría: getCategoryForContractItems(item?.contract_item?.categories),
      Aprovado: "X",
    };
    return data;
  });
};

const getLotNoForTranslatedLots = (item) => {
  const data = [];
  if (item?.lot_no) data.push(item?.lot_no);
  if (item?.lot_alpha) data.push(item?.lot_alpha);
  return data?.length > 0 ? data?.join("-") : "-";
};

// generate data for translation template
const generateTranslationTemplateData = (arrOfObj) => {
  return arrOfObj
    ?.map((item) => {
      // Check if the item meets the conditions
      if (
        item?.contract_item?.described === true &&
        item?.contract_item?.translated === false
      ) {
        const data = {
          "Nº Lote": getLotNoForTranslatedLots(item),
          "Nº Contrato": item?.contract_item?.contract_item_no || "-",
          Artista: item?.contract_item?.author?.id ? item?.contract_item?.author?.description : "-",
          Título: item?.contract_item?.title_pt || "-",
          Descrição: item?.contract_item?.description_pt || "-",
          Categoria: getCategoryForContractItems(item?.contract_item?.categories),
          Dimensões: item?.contract_item?.dimensions_pt || "-",
          Peso: item?.contract_item?.weight_pt || "-",
          "Estimativa mínima": item?.contract_item?.min_estimate || "-",
          "Estimativa máxima": item?.contract_item?.max_estimate || "-",
          Bibliografia: item?.contract_item?.literature_pt || "-",
          Proveniência: item?.contract_item?.provenance_pt || "-",
          Exposições: item?.contract_item?.exhibitions_pt || "-",
          Texto: item?.contract_item?.text_pt || "-",
          Notas: item?.contract_item?.notes_pt || "-",
          Title: item?.contract_item?.title_en || "-",
          Description: item?.contract_item?.description_en || "-",
          Dimensions: item?.contract_item?.dimensions_en || "-",
          Weight: item?.contract_item?.weight_en || "-",
          Literature: item?.contract_item?.literature_en || "-",
          Provenance: item?.contract_item?.provenance_en || "-",
          Exhibitions: item?.contract_item?.exhibitions_en || "-",
          Text: item?.contract_item?.text_en || "-",
          Notes: item?.contract_item?.notes_en || "-",
          "Valor Venda": item?.sale_value || "-",
        };
        return data;
      }
      return null; // Return null for items that don't meet the conditions
    })
    ?.filter(Boolean); // Remove null values from the array
};

// get lot description
const getLotDescription = (row) => {
  let description = [];
  if (row?.contract_item?.author?.id) description.push(row?.contract_item?.author?.description);

  if (i18next?.resolvedLanguage === "pt") {
    if (row?.contract_item?.title_pt) description.push(row?.contract_item?.title_pt);
    if (row?.contract_item?.description_pt) description.push(row?.contract_item?.description_pt);
  } else {
    if (row?.contract_item?.title_en) description.push(row?.contract_item?.title_en);
    if (row?.contract_item?.description_en) description.push(row?.contract_item?.description_en);
  }
  return description?.length > 0 ? description.join(", ") : "";
};

const getConcatedStringFromArray = (array, field) => {
  let string = [];
  if (array?.length === 0) {
    return "-";
  }
  array?.filter((lang) => string?.push(capitalizeFirstCharacterInString(lang[`${field}`])));
  return string?.join(", ");
};

const getListOfTagsBureaucracy = (row) => {
  const tagBureaucracyArray = [];
  TAG_BUREAUCRACY_OPTIONS.forEach((elem) => {
    if (row?.contract_item?.[elem?.value]) {
      tagBureaucracyArray.push(elem);
    }
  });

  return tagBureaucracyArray;
};

// generate general data for exporting
const generateGeneralExcelData = (arrOfObj) => {
  return arrOfObj?.map((item) => {
    const data = {
      [`${i18next.t("common.lot")} ${i18next.t("common.no_")}`]: item?.lot_no ? item?.lot_no + item?.lot_alpha : " ",
      [`${i18next.t("common.description")}`]: getLotDescription(item),
      [`${i18next.t("common.category")}`]:
      getConcatedStringFromArray(
        item?.contract_item?.categories,
        getColumnDetail("category_name_en", "category_name_pt"),
      ) || "-",
      [`${i18next.t("common.contractNo")}`]: item?.contract_item?.contract_item_no
        ? `${item?.contract_item?.contract_item_no}`
        : " ",
      [`${i18next.t("common.seller")}`]: item?.contract_item?.contract?.customer?.id
        ? getOnlyCustomerName(item?.contract_item?.contract?.customer)
        : "-",
      [`${i18next.t("common.paddle")}`]: item?.paddle?.id ? `${item?.paddle?.paddle_no}` : "-",
      [`${i18next.t("common.buyer")}`]:
        item?.paddle?.id && item?.paddle?.customer?.id ? getOnlyCustomerName(item?.paddle?.customer) : "-",
      [`${i18next.t("common.saleValue")}`]: item?.sale_value ? ` ${formatAmount(item?.sale_value)} €` : "-",
      [`${i18next.t("common.tags")}`]: item?.contract_item?.lots_tags
        ?.map((option) => getColumnDetail(option.tag_label, option.tag_label_pt))
        ?.toString(),
      [`${i18next.t("common.tags")} ${i18next.t("common.bureaucracy")}`]: getListOfTagsBureaucracy(item)
        ?.map((option) => option.label)
        ?.toString(),
    };
    return data;
  });
};

export const generateLotExcelData = (type, data) => {
  let widthArr = [];
  if (type === "saleroom") {
    widthArr = [20, 25, 45, 25, 25, 25, 25];
    return { data: generateSaleRoomExportData(data), widthArr };
  } else if (type === "invaluable") {
    widthArr = [20, 25, 45, 25, 25, 25, 25, 25, 15, 15, 15, 15, 15, 25, 25, 25, 25, 25, 15, 15];
    return { data: generateInvaluableExportData(data), widthArr };
  } else if (type === "artprice") {
    widthArr = [15, 20, 25, 40, 30, 20, 15, 15, 15, 15];
    return { data: generateArtPriceExportData(data), widthArr };
  } else if (type === "catalogue") {
    widthArr = [15, 20, 25, 40, 25, 25, 35, 25, 30, 25, 25];
    return { data: generateCatalogueExportData(data), widthArr };
  } else if (type === "generalPJMap") {
    widthArr = [30, 25, 25, 35, 25, 25, 30];
    return { data: generateGeneralPjMapExportData(data), widthArr };
  } else if (type === "jewelryPJMap") {
    widthArr = [30, 25, 30, 25, 25, 25, 25, 35];
    return { data: generateJewelryPjMapExportData(data), widthArr };
  } else if (type === "ASAEMap") {
    widthArr = [30, 25, 30, 25, 25, 35];
    return { data: generateASAEExportData(data), widthArr };
  } else if (type === "wordpress") {
    widthArr = [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
    return { data: generateWordpressExportData(data), widthArr };
  } else if (type === "template") {
    widthArr = [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
    return { data: generateTranslationTemplateData(data), widthArr };
  } else if (type === "generalExcel") {
    widthArr = [20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
    return { data: generateGeneralExcelData(data), widthArr };
  }
};

export const getExportLotFileName = (type) => {
  if (type === "saleroom") {
    return i18next.t("common.saleroom");
  } else if (type === "invaluable") {
    return i18next.t("common.invaluable");
  } else if (type === "artprice") {
    return `${i18next.t("common.art")} ${i18next.t("common.price")}`;
  } else if (type === "catalogue") {
    return `${i18next.t("common.catalogue")}`;
  } else if (type === "generalPJMap") {
    return `${i18next.t("common.generalPJMap")}`;
  } else if (type === "jewelryPJMap") {
    return `${i18next.t("common.jewelryPJMap")}`;
  } else if (type === "ASAEMap") {
    return `${i18next.t("common.ASAEMap")}`;
  } else if (type === "wordpress") {
    return `${i18next.t("common.wordpress")}`;
  } else if (type === "template") {
    return `${i18next.t("common.translationTemplate")}`;
  } else if (type === "generalExcel") {
    return `${i18next.t("common.generalExcel")}`;
  }
};

export const generatePaddlesExcelData = (arrOfObj) => {
  const excelData = arrOfObj?.map((item) => {
    const data = {
      [`${i18next.t("common.paddleNo")}`]: item?.paddle_no ? `${item?.paddle_no}` : "-",
      [`${i18next.t("common.customer")}`]: getOnlyCustomerName(item?.customer),
      [`${i18next.t("common.buyer'sAddress")}`]: item?.customer?.addresses
        ? getCustomerAddress(
          item?.customer?.addresses,
          item?.customer?.customer_type === "company" ? "company" : "client",
        )
        : "-",
      [`${i18next.t("common.totalPurchase")}`]: formatAmount(item?.total_purchase || 0) + " €",
    };
    return data;
  });

  const widthArr = [30, 30, 70, 30];
  return { data: excelData, widthArr };
};

export const generateExportPaddlesFileName = () => {
  return i18next.t("common.paddles");
};
